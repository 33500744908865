import Vue from 'vue'
import axios from 'axios'

// axios.defaults.baseURL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://faixaamarela.com.br/api/'
//     : 'http://localhost:8000/api/'

axios.defaults.baseURL = 'https://faixaamarela.com.br/api/'

let authErrors = 0

axios.interceptors.response.use(
  response => {
    authErrors = 0
    return response
  },
  async err => {
    const {
      config,
      response: { status, data }
    } = err

    const originalRequest = config
    authErrors++

    if (status === 401 && data.type !== 'login') {
      if (authErrors < 2) {
        await Vue.prototype.$jwt.refreshToken()
        const newAuthorization = await Vue.prototype.$jwt.getAccessToken()
        originalRequest.headers.Authorization = 'Bearer ' + newAuthorization
        originalRequest.url = originalRequest.url.replace('/api', '')
        return axios(originalRequest)
      } else {
        await Vue.prototype.$jwt.removeToken()
        location.reload()
      }
    } else {
      return Promise.reject(err)
    }
  }
)

Vue.prototype.$http = axios

export default {}
