const pages = [
  {
    path: 'coming_soon',
    name: 'Em breve',
    components: {
      default: () => import('@/components/ComingSoon.vue')
    }
  },
  {
    path: 'dashboard',
    name: 'Dashboard',
    components: {
      default: () => import('@/pages/Dashboard/Dashboard_temporary.vue')
    }
  },
  {
    path: '/drivers',
    name: 'drivers',
    components: {
      default: () => import('@/pages/Driver/DriverPage.vue')
    }
  },
  {
    path: '/drivers/new',
    name: 'drivers.new',
    components: {
      default: () => import('@/pages/Driver/NewEditDriver.vue')
    }
  },
  {
    path: '/drivers/new/:id',
    name: 'drivers.edit',
    components: {
      default: () => import('@/pages/Driver/NewEditDriver.vue')
    }
  },
  {
    path: '/clients',
    name: 'clients',
    components: {
      default: () => import('@/pages/Clients/ClientPage.vue')
    }
  },
  {
    path: '/clients/new',
    name: 'clients.new',
    components: {
      default: () => import('@/pages/Clients/NewEditClient.vue')
    }
  },
  {
    path: '/clients/new/:id',
    name: 'clients.edit',
    components: {
      default: () => import('@/pages/Clients/NewEditClient.vue')
    }
  },
  {
    path: '/districts',
    name: 'districts',
    components: {
      default: () => import('@/pages/District/DistrictPage.vue')
    }
  },
  {
    path: '/districts/new',
    name: 'discricts.new',
    components: {
      default: () => import('@/pages/District/NewEditDistrict.vue')
    }
  },
  {
    path: '/districts/new/:id',
    name: 'discricts.edit',
    components: {
      default: () => import('@/pages/District/NewEditDistrict.vue')
    }
  },
  {
    path: '/schools',
    name: 'schools',
    components: {
      default: () => import('@/pages/School/SchoolPage.vue')
    }
  },
  {
    path: '/schools/new',
    name: 'schools.new',
    components: {
      default: () => import('@/pages/School/NewEditSchool.vue')
    }
  },
  {
    path: '/schools/new/:id',
    name: 'schools.edit',
    components: {
      default: () => import('@/pages/School/NewEditSchool.vue')
    }
  },
  {
    path: '/publicities',
    name: 'publicities',
    components: {
      default: () => import('@/pages/Publicity/PublicityPage.vue')
    }
  },
  {
    path: '/publicities/new',
    name: 'publicities.new',
    components: {
      default: () => import('@/pages/Publicity/NewEditPublicity.vue')
    }
  },
  {
    path: '/publicities/new/:id',
    name: 'publicities.edit',
    components: {
      default: () => import('@/pages/Publicity/NewEditPublicity.vue')
    }
  },
  {
    path: 'usuarios',
    name: 'Usuários',
    components: {
      default: () => import('@/pages/Users/index.vue')
    }
  },
  {
    path: 'usuario',
    name: 'Novo Usuário',
    components: {
      default: () => import('@/pages/Users/form')
    }
  },
  {
    path: 'usuario/:id',
    name: 'Editar Usuário',
    components: {
      default: () => import('@/pages/Users/form')
    }
  },
  {
    path: 'categorias',
    name: 'Categorias',
    components: {
      default: () => import('@/pages/Category/index.vue')
    }
  },
  {
    path: 'categoria',
    name: 'Nova Categoria',
    components: {
      default: () => import('@/pages/Category/form.vue')
    }
  },
  {
    path: 'categoria/:id',
    name: 'Editar Categoria',
    components: {
      default: () => import('@/pages/Category/form.vue')
    }
  },
  {
    path: 'produtos',
    name: 'Produtos',
    components: {
      default: () => import('@/pages/Product/index.vue')
    }
  },
  {
    path: 'produto',
    name: 'Novo Produto',
    components: {
      default: () => import('@/pages/Product/form.vue')
    }
  },
  {
    path: 'produto/:id',
    name: 'Editar Produto',
    components: {
      default: () => import('@/pages/Product/form.vue')
    }
  },
  {
    path: 'noticias',
    name: 'Notícias',
    components: {
      default: () => import('@/pages/News/index.vue')
    }
  },
  {
    path: 'noticia',
    name: 'Nova Notícia',
    components: {
      default: () => import('@/pages/News/form.vue')
    }
  },
  {
    path: 'noticia/:id',
    name: 'Editar Notícia',
    components: {
      default: () => import('@/pages/News/form.vue')
    }
  },
  {
    path: 'pedidos',
    name: 'Pedidos',
    components: {
      default: () => import('@/pages/Cart/index.vue')
    }
  },
  {
    path: 'pedido/:id',
    name: 'Ver Pedido',
    components: {
      default: () => import('@/pages/Cart/form.vue')
    }
  }
]

export default pages
