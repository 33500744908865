<template>
  <div class="sidebar" :data="backgroundColor">
    <div class="sidebar-wrapper" ref="sidebarScrollArea">
      <div class="logo">
        <a href="#" class="simple-text logo-mini">
          <img :src="logo" alt="app-logo" />
        </a>
        <a href="#" class="simple-text logo-normal">
          {{ title }}
        </a>
      </div>
      <slot></slot>
      <ul class="nav">
        <slot name="links">
          <sidebar-item v-for="(link, index) in sidebarLinks" :key="link.name + index" :link="link">
            <sidebar-item v-for="(subLink, index) in link.children" :key="subLink.name + index" :link="subLink">
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
      <div class="user-type simple-text ">
        Versão: 1.2.5
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: '7Clicks',
      description: '7Clicks'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: '7Clicks'
    },
    logo: {
      type: String,
      default: '/favicon-white.png',
      description: '7Clicks'
    },
    backgroundColor: {
      type: String,
      default: 'primary',
      validator: value => {
        const acceptedValues = ['blue', 'green', 'orange', 'red', 'primary']
        return acceptedValues.indexOf(value) !== -1
      },
      description: 'Sidebar background color (blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },
  provide() {
    return {
      autoClose: this.autoClose
    }
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize()
      }
    }
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false
    }
  },
  computed: {
    ...mapState('auth', ['user'])
  }
}
</script>
<style>
@media (min-width: 992px) {

  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}

.user-type {
  position: absolute;
  bottom: 0.25rem;
  color: white;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-weight: 600;
  transition: 0.3s;
}

.sidebar-mini .user-type {
  opacity: 0;
}

.sidebar-mini .sidebar:hover .user-type {
  opacity: 1;
}
</style>
