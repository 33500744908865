import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import Jwt from '@/util/jwt'

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior: () => ({ x: 0, y: 0 })
})

router.beforeEach(async (to, _from, next) => {
  store.dispatch('loader/setLoader', true)
  const auth = await Jwt.check()
  store.dispatch('loader/setLoader', false)
  document.getElementsByTagName(
    'title'
  )[0].innerText = `${to.name} | Faixa Amarela`

  if (auth === 200) {
    if (to.path === '/login') return next('/')
    return next()
  }
  if (auth !== 200) {
    if (to.path === '/login') return next()
  }
  return next('/login')
})

export default router
